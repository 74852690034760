<template>
  <div>
    <div class="box one server">
      <h2 class="bold">
        {{ $t("507") }}
        <button class="small right" @click="handlePop">{{ $t("533") }}</button>
      </h2>
      <div class="flexL">
        <div>
          <p class="bold">{{ $t("508") }}</p>
          <select>
            <option value="1day">{{ $t("512") }}</option>
            <option value="2day">{{ $t("513") }}</option>
            <option value="3day">{{ $t("514") }}</option>
            <option value="4day">{{ $t("515") }}</option>
            <option value="5day">{{ $t("516") }}</option>
            <option value="6day">{{ $t("517") }}</option>
            <option value="7day">{{ $t("518") }}</option>
          </select>
        </div>
        <div>
          <p class="bold">{{ $t("509") }}</p>
          <select>
            <option value="30day">{{ $t("519") }}</option>
            <option value="60day">{{ $t("520") }}</option>
            <option value="90day">{{ $t("521") }}</option>
            <option value="180day">{{ $t("522") }}</option>
          </select>
        </div>
      </div>
      <div>
        <p class="bold">{{ $t("510") }}</p>
        <input type="text" />초
        <span>({{ $t("511") }})</span>
      </div>
      <button class="point large">{{ $t("524") }}</button>
    </div>
    <div class="box one">
      <h2 class="bold">채널 상태</h2>
      <table>
        <tr>
          <th>{{ $t("488") }}</th>
          <th>채널 URL</th>
          <th>{{ $t("528") }}</th>
          <th>동작상태</th>
          <th>할당 현장번호</th>
          <th>{{ $t("545") }}</th>
        </tr>
        <tr v-for="(data, i) in jobList" :key="i">
          <td>{{ data.name }}</td>
          <td>{{ data.liveURL }}</td>
          <td>{{ data.channelStatus }}</td>
          <td>
            {{$t(getChannelStatusName(data))}}
          </td>
          <td>{{ data.channelId }}</td>
          <td>{{ data.assignedUser.name }}</td>
        </tr>
        <!-- <tr>
          <td>Con0001</td>
          <td>102.34.145.12</td>
          <td>On</td>
          <td>Active</td>
          <td>123579</td>
          <td>홍길동</td>
        </tr> -->
      </table>
      <div class="pagination">
        <el-pagination
          small
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
          @current-change="handleCurrentChange"
          :current-page="currentPage + 1"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { fetchChannelList, getChannelStatusName } from "@/api/channel";
export default {
  props: {
    serverId: {
      type: String,
      default: "",
    },
    wowzaLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      jobList: [],
      total: 0,
      currentPage: 0,
      size: 10,
    };
  },
  mounted() {
    this.getJobList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getJobList();
    },
    getChannelStatusName(data){
      return getChannelStatusName(data);
    },
    getJobList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keyword: "",
        contentsId: "",
        serverId: this.serverId,
        // requestDateRange: {
        //   from: moment().format("YYYY-MM-DD"),
        //   to: moment().format("YYYY-MM-DD"),
        // },
        statuses: "",
      };
      fetchChannelList(params).then((res) => {
        this.total = res.data.data.total;
        this.jobList = res.data.data.content;
      });
    },
    handlePop() {
      this.index = this.index + 1;
      window.open(
        `/${localStorage.getItem("accountId")}/encoderLog?link=${
          this.wowzaLink
        }`,
        `encoder${this.index}`,
        "height=580,width=1024,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },
  },
};
</script>
